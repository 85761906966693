<template lang="pug">
.auth
  registration-step1(
    v-if="mode === 'step1'"
    v-model:email="payload.user.login"
    @auth="$emit('set:mode', 'authByLogin')"
    @recovery="$emit('set:mode', 'passwordRecovery')"
    @set:email="$emit('set:email', payload.user.login)"
    @switch-step="mode = $event"
  )
  registration-step2(
    v-else-if="mode === 'step2'"
    v-model:payload="payload"
    @switch-step="mode = $event"
    @result="$emit('show:result', $event)"
  )
  agreement(v-if="mode !== 'step3'" title="Продолжая регистрацию" mode="agreement")

// fast-auth(v-if="mode === 'step1'")
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import type { RegistrationCompanyI } from "@/stores/auth/UserInterface";

import FastAuth from "@/components/authorization/FastAuth.vue";
import RegistrationStep1 from "@/components/authorization/dialogContent/RegistrationStep1.vue";
import RegistrationStep2 from "@/components/authorization/dialogContent/RegistrationStep2.vue";
import Agreement from "@/components/authorization/dialogContent/Agreement.vue";

export default defineComponent({
  name: "RegistrationForm",
  components: {
    Agreement,
    FastAuth,
    RegistrationStep1,
    RegistrationStep2,
  },
  emits: [
    'set:mode',
    'set:email',
    'show:result',
  ],
  setup() {

    const mode = ref('step1');

    const payload = ref<RegistrationCompanyI>({
      inn: '',
      title: '',
      user: {
        firstName: '',
        middleName: '',
        surName: '',
        phone: '',
        login: '',
      }
    })

    return {
      mode,
      payload,
    }
  }
});
</script>

<style scoped lang="scss">
@import '@/assets/styles/landing/auth';
</style>
