<template lang="pug">
form.auth
  .auth__header
    span.label Авторизация
    span.description
      | Нет аккаунта?
      |
      span.link(@click="$emit('set:mode', 'registration')") Зарегистрируйтесь
  .auth__content(v-loading="loader")
    form-input(
      v-model="payload.login"
      placeholder="Введите логин"
      label="Логин"
      autocomplete="username"
    )
    password-input(
      v-model="payload.password"
      autocomplete="current-password"
      placeholder="Введите пароль"
      label="Пароль"
    )
    ui-checkbox(v-model="payload.rememberMe" id="remember-me" label="Запомнить меня")
    span.error-message(v-if="message") {{ message }}
  .auth__actions
    ui-button.full-width(:loader="loader" @click="loginEvent") Вход
    ui-button.full-width(type="tertiary" @click="recoveryPassword") Восстановление пароля

// fast-auth
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import { useApi } from "~/use/api/useApi";
import { useServiceStorage } from "~/use/serviceStorage/useServiceStorage";

import FormInput from "@/components/ui/form/input/FormInput.vue";
import FastAuth from "@/components/authorization/FastAuth.vue";
import PasswordInput from "@/components/ui/form/input/PasswordInput.vue";
import UiButton from "@/components/ui/button/UiButton.vue";
import UiCheckbox from "@/components/ui/checkbox/UiCheckbox.vue";

import type { LoginI } from "@/use/api/ApiInterface";
import useAuthStore from "@/stores/auth/useAuthStore";

export default defineComponent({
  name: "AuthByLogin",
  components: {
    UiButton,
    UiCheckbox,
    FormInput,
    FastAuth,
    PasswordInput,
  },
  emits: [
    'set:mode',
    'set:email',
  ],
  props: {
    email: {
      type: String,
      default: '',
    },
  },
  setup(props, context) {

    const loader = ref(false);
    const message = ref('');

    const authStore = useAuthStore();
    const { storage } = useServiceStorage();

    const payload = ref<LoginI>({
      login: props.email || '',
      password: '',
      rememberMe: true,
    })

    function loginEvent() {
      loader.value = true;
      storage.onLogin = true;

      useApi().security.fetchLogin(payload.value)
        .then(() => authStore.getUsersMe(true))
        .catch(() => message.value = 'Указан неверный логин или пароль')
        .finally(() => loader.value = false)
    }

    watch(payload, () => {
      message.value = ''
    }, { deep: true })

    function recoveryPassword() {
      context.emit('set:email', payload.value.login)
      context.emit('set:mode', 'passwordRecovery')
    }

    return {
      message,
      payload,
      loader,
      loginEvent,
      recoveryPassword,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/styles/landing/auth';
</style>
