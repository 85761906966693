<template lang="pug">
.auth__header
  span.label Регистрация
  span.description
    | Уже есть аккаунт?
    |
    span.link(@click="goToAuth") Войти

.auth__content
  validation-row(
    ref="refEmail"
    required
    hide-asterisk
    not-empty
    type-email
    :model-value="email"
    label="Контактный email"
    placeholder="Введите email"
    @error="errorChecker('email')"
    @correct="correctChecker('email')"
    @update:modelValue="$emit('update:email', $event)"
    @keyup.enter="validate"
  )
  span.error-message(v-if="errorStatus && errorStatus !== 412") Произошла ошибка, наши программисты уже работают над этим.
  template(v-if="errorStatus === 412")
    span.error-message Указанный адрес электронной почты уже зарегистрирован в системе.
    span.error-message Для входа в систему используйте пароль, отправленный на указанную почту, или воспользуйтесь функцией
      |
      |
      span.link(@click="goToRecovery") восстановления пароля.

.auth__actions
  ui-button.full-width(:loader="loader" @click="validate") Продолжить
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import { useForm } from "@/use/other/useForm";
import { useApi } from "~/use/api/useApi";

import UiButton from "@/components/ui/button/UiButton.vue";
import ValidationRow from "@/components/ui/form/input/ValidationRow.vue";
import { sendMetrica, YmGoals } from "~/utils/logger/metrica";

export default defineComponent({
  name: "RegistrationStep1",
  components: {
    UiButton,
    ValidationRow,
  },
  emits: [
    'auth',
    'recovery',
    'switchStep',
    'update:email',
    'set:email',
  ],
  props: {
    email: {
      type: String,
      default: '',
    },
  },
  setup(props, context) {

    const loader = ref(false);
    const errorStatus = ref();

    const refEmail = ref();

    function saveFormContinue() {
      loader.value = true
      errorStatus.value = undefined

      useApi().security.fetchEmailExist(props.email)
        .then(() => context.emit('switchStep', 'step2'))
        .catch((error) => errorStatus.value = error?.response?.status || 404)
        .finally(() => loader.value = false)
    }

    const { errList, validateForm, errorChecker, correctChecker } = useForm(saveFormContinue);

    function goToAuth() {
      refEmail.value.validate()
      if (!errList.value.length) context.emit('set:email')
      context.emit('auth')
    }

    function goToRecovery() {
      context.emit('set:email')
      context.emit('recovery')
    }

    function validate() {
      refEmail.value.validate()
      validateForm()
    }

    watch(() => props.email, () => {
      errorStatus.value = undefined
    })

    sendMetrica(YmGoals.RegistrationClick)

    return {
      errorStatus,
      refEmail,
      loader,
      goToRecovery,
      goToAuth,
      errorChecker,
      correctChecker,
      validate,
    }
  }
});
</script>

<style scoped lang="scss">
@import '@/assets/styles/landing/auth';
</style>
