<template lang="pug">
.auth__header
  .title(@click="$emit('switchStep', 'step1')")
    ui-icon(:size="20" :icon="UiIconNames.Icon_Back")
    span.label Регистрация
  span.description
    | Введите данные пользователя
    |
    b {{ payload.user.login }}
.auth__content(v-loading="loader")
  validation-row(
    ref="refSurName"
    required
    not-empty
    v-model="payload.user.surName"
    label="Фамилия"
    placeholder="Введите фамилию"
    @error="errorChecker('surName')"
    @correct="correctChecker('surName')"
  )
  validation-row(
    ref="refFirstName"
    required
    not-empty
    v-model="payload.user.firstName"
    label="Имя"
    placeholder="Введите имя"
    @error="errorChecker('firstName')"
    @correct="correctChecker('firstName')"
  )
  form-input(
    v-model="payload.user.middleName"
    label="Отчество"
    placeholder="Введите отчество"
  )
  validation-row(
    ref="refPhone"
    required
    not-empty
    type-phone
    v-model="payload.user.phone"
    label="Телефон"
    placeholder="Введите телефон"
    @error="errorChecker('phone')"
    @correct="correctChecker('phone')"
  )
  form-input(
    v-model="payload.title"
    label="Наименование организации"
    placeholder="Введите название организации"
  )
  validation-row(
    ref="refInn"
    custom
    hide-asterisk
    v-model="payload.inn"
    label="ИНН"
    placeholder="Введите ИНН"
    :custom-checker="customInnChecker"
    @error="errorChecker('inn')"
    @correct="correctChecker('inn')"
  )
  span.error-message(v-if="message") {{ message }}
.auth__actions
  ui-button.full-width(:loader="loader" @click="validate") Продолжить
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { useForm } from "@/use/other/useForm";
import { useApi } from "@/use/api/useApi";
import { customInnChecker } from "~/utils/checkers/checkers";
import { storeToRefs } from "pinia";
import { sendMetrica, YmGoals } from "~/utils/logger/metrica";

import UiIcon from "@/components/ui/icon/UiIcon.vue";
import UiButton from "@/components/ui/button/UiButton.vue";
import FormInput from "@/components/ui/form/input/FormInput.vue";
import ValidationRow from "@/components/ui/form/input/ValidationRow.vue";

import type { PropType } from "vue";
import type { RegistrationCompanyI } from "@/stores/auth/UserInterface";
import UiIconNames from "@/components/ui/icon/UiIconNames";
import useSearchStore from "~/stores/search/useSearchStore";
import useAuthStore from "~/stores/auth/useAuthStore";

export default defineComponent({
  name: "RegistrationStep2",
  components: {
    UiIcon,
    UiButton,
    FormInput,
    ValidationRow,
  },
  methods: {
    customInnChecker,
  },
  emits: [
    'update:payload',
    'switchStep',
    'result',
  ],
  props: {
    payload: {
      type: Object as PropType<RegistrationCompanyI>,
      default: () => ({}),
    },
  },
  setup(props, context) {

    const message = ref('')
    const loader = ref(false)

    const refFirstName = ref()
    const refSurName = ref()
    const refPhone = ref()
    const refInn = ref()

    const authStore = useAuthStore()
    const searchStore = useSearchStore()
    const { searchPageMode } = storeToRefs(searchStore)

    function validate() {
      refFirstName.value.validate()
      refSurName.value.validate()
      refPhone.value.validate()
      refInn.value.validate()
      validateForm()
    }

    function register() {
      loader.value = true
      message.value = ''

      useApi().companies.postCompany({
        ...props.payload,
        title: props.payload?.title || undefined,
        promoCampaign: authStore.campaign || undefined,
      })
        .then(() => {
          sendMetrica(YmGoals.RegistrationGoal)
          searchPageMode.value = 'simpleSearchMode'
          context.emit('result', { s: 'success', m: 'register', e: props.payload.user.login })
        })
        .catch(() => message.value = 'Произошла непредвиденная ошибка. Попробуйте позже')
        .finally(() => loader.value = false);
    }

    const { validateForm, errorChecker, correctChecker } = useForm(register);

    return {
      loader,
      message,
      refFirstName,
      refSurName,
      refPhone,
      refInn,
      validate,
      errorChecker,
      correctChecker,
      UiIconNames,
    }
  }
});
</script>

<style scoped lang="scss">
@import '@/assets/styles/landing/auth';
</style>
