<template lang="pug">
el-container#dashboard-container
  el-header.dashboard-header
    landing-header
  el-main.dashboard-main
    template(v-if="isAvailableForNonAuth")
      slot
    template(v-else)
      div
  el-footer.dashboard-footer
    main-footer
</template>

<script lang="ts">
import { useRoute } from "vue-router";
import { computed, defineComponent } from "vue";

import MainFooter from "@/components/footer/MainFooter.vue";
import LandingHeader from "@/components/header/LandingHeader.vue";
import { useServiceStorage } from "~/use/serviceStorage/useServiceStorage";

export default defineComponent({
  name: "dashboard",
  components: {
    LandingHeader,
    MainFooter,
  },
  setup() {

    const route = useRoute();
    const isAvailableForNonAuth = computed(() => !route.meta.requiresAuth);

    const { storage } = useServiceStorage();
    storage.onLogout = false;

    return {
      isAvailableForNonAuth,
    }
  },
});
</script>

<style scoped lang="scss">
#dashboard-container {
  .dashboard-header {
    position: sticky;
    top: 0;
    z-index: 5000;
    box-shadow: 0 5px 10px rgba(188, 186, 190, 0.2);
    padding: 0;
    --el-header-height: var(--landing-header-height);
  }

  .dashboard-main {
    align-items: center;
    padding: 0;
    min-height: 100vh;
    overflow: hidden;
    //overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #f8fafc;
    //background-image: url('/pics/landing/background-img.jpg');
    background-repeat: repeat;
    background-size: initial;
    background-attachment: local;
  }

  .dashboard-footer {
    display: flex;
    flex-flow: column;
    align-self: center;
    height: auto;
    padding: 0;
    width: 100%;
    max-width: 1140px;
    background-color: var(--main-color-white);
  }
}
</style>
