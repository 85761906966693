<template lang="pug">
.auth
  .auth__header(v-if="status === 'success'")
    span.label {{ mode === 'recovery' ? 'Пароль обновлен.' : 'Вы зарегистрированы в системе.' }}
    span.description
      | На почту
      |
      b {{ email }}
      |
      | было отправлено письмо {{ mode === 'register' ? 'с паролем и ссылкой для входа.' : 'со ссылкой для сброса пароля.' }}
      |
      | Пожалуйста,
      |
      span.link(@click="$emit('set:mode', 'authByLogin')") авторизуйтесь.
  .auth__header(v-else-if="status === 'error'")
    span.label Ошибка
    span.description Произошла ошибка, попробуйте позднее
  .auth__actions
    ui-button.fit-content(type="secondary" @click="$emit('close:dialog')") Закрыть
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { PropType } from "vue";

import UiButton from "@/components/ui/button/UiButton.vue";

export default defineComponent({
  name: "ResultMessage",
  emits: [
    'set:mode',
    'close:dialog',
  ],
  components: {
    UiButton,
  },
  props: {
    email: {
      type: String,
      default: '',
    },
    mode: {
      type: String as PropType<'register'|'recovery'>,
      default: 'register'
    },
    status: {
      type: String as PropType<'success'|'error'>,
      default: ''
    },
  },
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/landing/auth';
</style>
